<template>
  <div>
    <Cell class="top_bar" center>
      <template #title>
        <div class="left_wrap">
          <span class="level">{{ `${$t('member.title.level')} ${data.fromlevel || 1}:` }}</span>
          <div class="copy_wrap" @click="copy(data.username || '')">
            <span>{{ data.username || "" }}</span>
            <i class="iconfont icon-shangpinfuzhi copy"></i>
          </div>
        </div>
      </template>
    </Cell>
    <Cell class="content_bar">
      <VanImage :error-icon="require(`@tt/assets/ico/${ver}.png`)" class="left" :src="data.userimg" />
      <div class="right">
        <p v-if="!!data.linedate">{{ `${$t('member.label.loginData')}: ${$format(data.linedate, "dd-MM-yy HH:mm")}`
        }}</p>
        <p>{{ `${$t('member.label.indate')}: ${$format(data.regdate, "dd-MM-yy HH:mm")}` }}</p>
        <p v-if="!data.linedate">{{ $t('member.notLogin') }}</p>
        <p v-if="showSuper">{{ `${$t('member.label.superior')}:` }}&nbsp;<span>{{ data.agent ? data.agent.username : ""
        }}</span></p>
      </div>
    </Cell>
    <Cell v-if="!rightLower" class="bottom_bar" :to="route ? `/memberDetails?id=${data.id}` : ''" :is-link="showLink"
      value-class="to_details" :value="showToDetails ? $t('member.toDetails') : ''"
      :title="`x${data.child_num || 0} ${$t('member.label.member')}`" title-class="title_style"></Cell>
    <Cell v-if="rightLower" class="bottom_bar" :to="route ? `/memberDetails?id=${data.id}` : ''" :is-link="showLink"
      value-class="title_style" :value="`x${data.child_num || 0} ${$t('memberDetails.lowerMember')}`" title=""></Cell>
  </div>
</template>

<script>
import { Cell, Image as VanImage } from 'vant';
export default {
  components: {
    Cell, VanImage
  },
  props: {
    data: {
      type: Object,
      require: true,
      default: () => ({})
    },
    showSuper: {
      type: Boolean,
      default: true
    },
    showToDetails: {
      type: Boolean,
      default: true
    },
    showLink: {
      type: Boolean,
      default: true
    },
    rightLower: {
      type: Boolean,
      default: false
    },
    route: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}

.top_bar {
  border-bottom: 1px solid #eee;
  margin-top: calc(12rem / 16);

  .left_wrap {
    .flex();
    align-items: center;

    .copy_wrap {
      border: 1px dashed var(--theme);
      padding: 2px 10px;
      align-items: center;
      .flex();
      align-items: center;
      margin-left: 5px;
      border-radius: 4px;

      .copy {
        font-size: 24px;
        color: var(--theme);
        margin-left: 8px;
      }
    }
  }
}

.content_bar {
  /deep/ .van-cell__value {
    .flex();
  }

  .left {
    width: 36px;
    height: 36px;
    margin: 10px 20px 2px 0;
  }

  .right {
    >p {
      font-size: calc(14rem / 16);
      color: #999;

      >span {
        color: var(--theme);
      }
    }
  }
}

.bottom_bar {
  border-top: 1px solid #eee;

  .to_details {
    color: var(--theme);
  }

  .title_style {
    color: #999;
  }
}
</style>